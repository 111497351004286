import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--lato);
  margin: 5px;
  color: var(--white);
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-size: 1.2rem;
  font-family: var(--lato);
  margin: 10px;
  color: var(--white);
  text-align: center;
`;

export const Text = styled.h4`
  font-size: 1rem;
  font-family: var(--lato);
  margin: 7px;
  color: var(--white);
  text-align: center;
`;

export const Description = styled.span`
  font-size: 0.9rem;
  font-family: var(--lato);
  margin: 7px;
  color: var(--white);
  line-height: 1.6;
`;
