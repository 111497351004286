import React, { ChangeEvent } from 'react';
import { FaBook, FaCalendarCheck, FaUniversity } from 'react-icons/fa';
import IconTextInput from '../../../components/IconTextInput';
import MaskedIconTextInput from '../../../components/IconTextInput/masked';
import TextArea from '../../../components/TextArea';
import { Text } from '../../../styles/typography';
import { handleChange } from '../../../utils';

// import { Container } from './styles';
interface ScholarProps {
  setData: (prevState: any) => void;
  data: any;
}
const Scholar = ({ setData, data }: ScholarProps) => {
  return (
    <>
      <Text>Nos fale um pouco sobre seus estudos!</Text>
      <IconTextInput
        name="university"
        placeholder="Universidade *"
        icon={FaUniversity}
        defaultValue={data.university}
        onChange={e => handleChange(e, setData)}
      />
      <IconTextInput
        name="course"
        placeholder="Curso *"
        icon={FaBook}
        defaultValue={data.course}
        onChange={e => handleChange(e, setData)}
      />
      <MaskedIconTextInput
        name="graduation"
        placeholder="Mês e Ano de Graduação *"
        icon={FaCalendarCheck}
        format={'##/####'}
        defaultValue={data.graduation}
        onChange={e => handleChange(e, setData)}
      />
      <TextArea
        name="languages"
        placeholder="Linguagens de programação *"
        onChange={e => handleChange(e, setData)}
        defaultValue={data.languages}
      />
    </>
  );
};

export default Scholar;
