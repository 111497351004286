import React, { ComponentType, InputHTMLAttributes, ReactElement } from 'react';
import { IconBaseProps } from 'react-icons';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Container, Input, IconHolder } from './styles';

interface MaskedIconTextInputProps extends NumberFormatProps {
  icon: ComponentType<IconBaseProps>;
  iconColor?: string;
  textColor?: string;
}

const defaultProps: Partial<MaskedIconTextInputProps> = {
  iconColor: '#fff',
  textColor: '#fff',
};

const MaskedIconTextInput = ({
  icon: Icon,
  iconColor,
  textColor,
  ...rest
}: MaskedIconTextInputProps): ReactElement => {
  return (
    <Container>
      {Icon && (
        <IconHolder>
          <Icon size={25} color={iconColor} />
        </IconHolder>
      )}
      <NumberFormat
        {...rest}
        customInput={Input}
        style={
          Icon
            ? { color: textColor, paddingLeft: '35px' }
            : { color: textColor }
        }
      />
    </Container>
  );
};

MaskedIconTextInput.defaultProps = defaultProps;

export default MaskedIconTextInput;
