import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Admin from '../../layout/Admin';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableTheme } from '../../utils';
import useApi from '../../hooks/useApi';
import { PSelData } from '../../types';
import DetailPanel from '../../components/DetailPanel';
// import { Container } from './styles';

const PSel: React.FC = () => {
  const { data } = useApi('/subscriber');

  const dateFormat = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month =
      date.getMonth() + 1 <= 9
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    const formatted = day + '/' + month + '/' + year;
    return formatted;
  };

  if (!data) {
    return (
      <Admin>
        <p>Loading...</p>
      </Admin>
    );
  }
  return (
    <Admin>
      <ThemeProvider theme={tableTheme}>
        <MaterialTable
          columns={[
            { title: 'Nome', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Telefone', field: 'phone' },
            {
              title: 'Universidade',
              field: 'university',
            },
            {
              title: 'Data de Inscrição',
              field: 'lastTry',
              // eslint-disable-next-line react/display-name

              render: rowData => dateFormat(rowData.lastTry),
            },
            {
              title: 'CPF',
              field: 'cpf',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Linguagens',
              field: 'languages',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Previsão de Formação',
              field: 'graduation',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Curso',
              field: 'course',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Metas a Curto Prazo',
              field: 'shortTerm',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Metas a Longo Prazo',
              field: 'longTerm',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Indicado por',
              field: 'indicatedBy',
              searchable: false,
              hidden: true,
              export: true,
            },
            {
              title: 'Descobriu o psel através',
              field: 'discoveredBy',
              searchable: false,
              hidden: true,
              export: true,
            },
          ]}
          data={data.subscribers}
          options={{
            exportButton: true,
            rowStyle: {
              borderColor: 'var(--secondary)!important',
            },
            headerStyle: {
              backgroundColor: 'var(--primary-500)',
              color: 'var(--white)',
            },
            searchFieldStyle: {
              borderColor: 'var(--secondary)',
            },
          }}
          title="Candidatos"
          detailPanel={rowData => <DetailPanel data={rowData} />}
        />
      </ThemeProvider>
    </Admin>
  );
};

export default PSel;
