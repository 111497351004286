import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import { PrivateRoute } from './auth';
import routes from './routes';

const Routes = () => {
  // const history = useHistory();

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  return (
    <Router>
      <Switch>
        {routes.public.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            component={() => <route.component />}
          />
        ))}
        {routes.protected.map((route, index) => (
          <PrivateRoute
            key={index}
            path={route.path}
            exact
            component={() => <route.component />}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default Routes;
