import React, { useState } from 'react';
import { FaKey, FaUser } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button/styles';
import IconTextInput from '../../components/IconTextInput';
import api from '../../services/api';
import { login } from '../../services/auth';
import { LoginData } from '../../types';
import { handleChange } from '../../utils';

import { Box, Container, Anchor, Logo } from './styles';

const Login: React.FC = () => {
  const [data, setData] = useState<LoginData>({
    email: '',
    password: '',
  });

  const handleSubmit = async () => {
    if (data.email !== '' && data.password !== '') {
      console.log(data);
      await api
        .post('/auth', data)
        .then(({ data }) => {
          login(data.token.token);
          toast.success(data.message);
          history.push('/dashboard');
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
        });
    } else {
      toast.error('Preencha todos os campos!');
    }
  };
  const history = useHistory();
  return (
    <Container>
      <Box>
        <Anchor>
          <Logo src="assets/images/logo.png" />
        </Anchor>
        <IconTextInput
          icon={FaUser}
          placeholder="Email"
          name="email"
          onChange={e => handleChange(e, setData)}
        />
        <IconTextInput
          type="password"
          name="password"
          placeholder="******"
          onChange={e => handleChange(e, setData)}
          icon={FaKey}
        />
        <Button onClick={handleSubmit}>ENTRAR</Button>
      </Box>
    </Container>
  );
};

export default Login;
