import React, { useEffect, useState } from 'react';
import { Container, PageTitle, User, Name, Avatar } from './styles';
import { useHistory } from 'react-router-dom';
import { getUserData } from '../../services/auth';

export default function Topbar() {
  const history = useHistory();
  const userData: any = getUserData();
  const [title, setTitle] = useState('');
  useEffect(() => {
    const pathname = history.location.pathname.split('/');
    const aux = pathname[1].split('-');
    if (aux.length >= 2) {
      setTitle(`${aux[0]} ${aux[1]}`);
      console.log(title);
    } else {
      setTitle(pathname[1]);
    }
  }, []);
  useEffect(() => console.log(userData), []);
  return (
    <Container>
      <PageTitle>{title[1] !== '' ? title : 'Dashboard'}</PageTitle>
      <User>
        <Name>
          {userData.username} - {userData.role}
        </Name>
        {/* <Avatar /> */}
      </User>
    </Container>
  );
}
