import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;

  align-items: center;
  justify-content: space-around;

  width: min(100%, 400px);
  margin: 10px;
`;
export const NumberHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--secondary-100);
  border-radius: var(--radius-circle);
  color: var(--white);
  &.active {
    background-color: var(--secondary);
  }
`;
export const Line = styled.div`
  height: 1px;
  flex: 1;
  background-color: var(--white);
`;
