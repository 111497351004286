import { createGlobalStyle } from 'styled-components';
const Global = createGlobalStyle`

@font-face {
    font-family: "Cera Pro";
    src: url("assets/fonts/cera-pro-sv/Cera Pro Black Italic.otf"),
       url("assets/fonts/cera-pro-sv/Cera Pro Black.otf"),
       url("assets/fonts/cera-pro-sv/Cera Pro Bold.otf"), 
       url("assets/fonts/cera-pro-sv/Cera Pro Medium.otf"), 
       url("assets/fonts/cera-pro-sv/Cera Pro Light.otf"), 
       url("assets/fonts/cera-pro-sv/Cera Pro Regular Italic.otf"); 
  }
     
    html{
      margin: 0;
      padding:0;
      font-family: var(--lato);

    }
    body{
        margin: 0;
        width: 100%;
        height: 100%;
        overflow-x:hidden;
        overflow-y:auto;
        
    }
   
    
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
    
    
    :root{
        --primary: #2e2e2e;
        --primary-500: #212121;
        --primary-600: #1b1a1a;
        --primary-700: #0c0a0a;
        --secondary: #dc1e45;
        --secondary-100: #8D001C;
        --secondary-800:#E84767;
        --white: #fff;
        --silver:#1F1300;
        --gray: #8E8E8E;
        --dark: #000;
        --light-gray: #f1f1f1;
        --red: #e02030;
        --pure-red:#ff0000;
        --light-blue: #24C9C3;
        --radius-sm: 4px;
        --radius-md: 12px;
        --radius-lg: 24px;
        --radius-circle: 50%;
        --font-mute: 10px;
        --font-sm: 16px;
        --font-md: 18px;
        --font-lg: 32px;
        --republic: 'HWT Republic Gothic', sans-serif;
        --lato: 'Lato', sans-serif;
        --cera: 'Cera Pro', sans-serif;

        
    }
    
`;

export default Global;
