import React, { ComponentType, InputHTMLAttributes, ReactElement } from 'react';
import { IconBaseProps } from 'react-icons';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Container, Input, IconHolder } from './styles';

interface IconTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: ComponentType<IconBaseProps>;
  iconColor?: string;
  textColor?: string;
  mask?: string;
}

const defaultProps: Partial<IconTextInputProps> = {
  iconColor: '#fff',
  textColor: '#fff',
  mask: '',
};

const IconTextInput = ({
  icon: Icon,
  iconColor,
  textColor,
  mask,
  ...rest
}: IconTextInputProps): ReactElement => {
  return (
    <Container>
      {Icon && (
        <IconHolder>
          <Icon size={25} color={iconColor} />
        </IconHolder>
      )}

      <Input
        style={
          Icon
            ? { color: textColor, paddingLeft: '35px' }
            : { color: textColor }
        }
        {...rest}
      />
    </Container>
  );
};

IconTextInput.defaultProps = defaultProps;

export default IconTextInput;
