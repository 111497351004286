import React from 'react';
import {
  Container,
  Header,
  Logo,
  Body,
  Footer,
  TextMuted,
  Logout,
} from './styles';
import NavLink from '../NavLink';
import routes from '../../routes/routes';
import { logout } from '../../services/auth';
import { useHistory } from 'react-router';
import { ImExit } from 'react-icons/im';
export default function Sidebar() {
  const history = useHistory();
  return (
    <Container>
      <Header>
        <Logo src="assets/images/logo.png" alt="Logo" />
      </Header>
      <Body>
        {routes.protected.map(
          route =>
            !route?.hidden && (
              <NavLink
                title={route.title}
                icon={route.icon}
                dropdown={route?.dropdown}
                path={route.path}
              />
            ),
        )}
        <Logout
          onClick={() => {
            logout();
            history.push('/login');
          }}
        >
          <ImExit /> {'   '} Sair
        </Logout>
      </Body>

      <Footer>
        <TextMuted>Proudly developed by Mind Consulting</TextMuted>
      </Footer>
    </Container>
  );
}
