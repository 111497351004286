import * as jwtDecode from 'jwt-decode';
export const isAuthenticated = () => localStorage.getItem('jwt') !== null;
export const getToken = () => localStorage.getItem('jwt');
export const login = (token: string) => {
  localStorage.setItem('jwt', token);
};
export const logout = () => {
  localStorage.removeItem('jwt');
};
const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
export const getUserData = () => {
  const token = localStorage.getItem('jwt');
  let user;
  token ? (user = parseJwt(token)) : (user = false);
  return user.data;
};
