import styled from 'styled-components';

export const TextAreaInput = styled.textarea`
  width: min(300px, calc(100% - 30px));
  border-radius: 5px;
  margin: 10px;
  border: 1px solid #822929;
  background: transparent;
  height: 70px;
  font-weight: bold;
  font-family: var(--cera);
  color: var(--white);
  cursor: text;
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.2s;
  padding: 15px;
  &:focus {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    input {
      border-color: #e42333;
    }
    outline: unset;
  }
`;
