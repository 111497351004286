import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button/styles';
import Stepper from '../../components/Stepper';
import api from '../../services/api';
import { Subtitle, Title } from '../../styles/typography';
import { PSelData } from '../../types';
import Objectives from './Objectives';
import Personal from './Personal';
import Recomendation from './Recomendation';
import Scholar from './Scholar';

import { Container, Box, Form } from './styles';

const FormPSel: React.FC = () => {
  const [step, setStep] = useState(1);
  const history = useHistory();
  const [data, setData] = useState<PSelData>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    university: '',
    graduation: '',
    course: '',
    languages: '',
    shortTerm: '',
    longTerm: '',
    indicatedBy: '',
    discoveredBy: '',
  });
  const steps = 4;

  const handleNextStep = () => {
    const {
      name,
      email,
      cpf,
      phone,
      university,
      graduation,
      course,
      languages,
      shortTerm,
      longTerm,
    } = data;

    switch (step) {
      case 1:
        if (name !== '' && email !== '' && phone !== '' && cpf !== '') {
          setStep(step + 1);
        } else {
          toast.error('Por favor, preencha todos os campos!');
        }
        break;
      case 2:
        if (
          university !== '' &&
          graduation !== '' &&
          languages !== '' &&
          course !== ''
        ) {
          setStep(step + 1);
        } else {
          toast.error('Por favor, preencha todos os campos!');
        }
        break;
      case 3:
        if (shortTerm !== '' && longTerm !== '') {
          setStep(step + 1);
        } else {
          toast.error('Por favor, preencha todos os campos!');
        }
        break;
      case 4:
        handleSubmit();
        break;

      default:
        return <Personal setData={setData} data={data} />;
        break;
    }
  };

  const handleSubmit = () => {
    api
      .post('subscriber', data)
      .then(({ data }) => {
        if (data.status === 201) {
          toast.success(data.message);
          history.push('boa-sorte!');
        } else {
          toast.error(data.message);
          window.location.reload();
        }
      })
      .catch(error => {
        if (error?.response) {
          toast.error(error.response.data.message);
        } else {
          console.log(error);
          toast.error('Oops, houve algum erro em nosso servidor!');
        }
      });
  };

  const handleSteps = () => {
    switch (step) {
      case 1:
        return <Personal setData={setData} data={data} />;
      case 2:
        return <Scholar setData={setData} data={data} />;
      case 3:
        return <Objectives setData={setData} data={data} />;
      case 4:
        return <Recomendation setData={setData} data={data} />;

      default:
        return <Personal setData={setData} data={data} />;
        break;
    }
  };

  return (
    <Container>
      <Box>
        <Title>Processo Seletivo</Title>
        <Subtitle>Web/Mobile Developer</Subtitle>
        <Stepper steps={steps} actualStep={step} setStep={setStep} />
        <Form>{handleSteps()}</Form>
        <Button onClick={handleNextStep}>
          {step === steps ? 'Finalizar' : 'Avançar'}
        </Button>
      </Box>
    </Container>
  );
};

export default FormPSel;
