import styled from 'styled-components';

export const Container = styled.div`
  background: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
`;
export const Anchor = styled.a`
  text-align: center;
`;

export const Logo = styled.img`
  width: min(100%, 500px);
  height: auto;
`;

export const Box = styled.div`
  background: var(--primary-600);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(100%, 700px);
  min-height: 450px;

  border-radius: 5px;
  box-shadow: 2px 2px 30px 10px rgba(40, 40, 40, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/images/footer.png);
  background-position: center center;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
`;
