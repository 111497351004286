import React, { ComponentType, useState } from 'react';
import { Container, DropItem, Dropdown, Link } from './styles';
import { useHistory } from 'react-router-dom';
import { IconBaseProps } from 'react-icons/lib';

interface NavLinkProps {
  dropdown?: {
    title: string;
    path: string;
    icon: ComponentType<IconBaseProps>;
    component: React.FC;
    hidden: any;
  }[];

  title: string;
  path: string;
  icon?: ComponentType<IconBaseProps>;
}

const defaultProps: Partial<NavLinkProps> = {
  dropdown: undefined,
};

const NavLink = ({ dropdown, title, path, icon }: NavLinkProps) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Container>
      <Link
        onClick={
          dropdown !== undefined
            ? () => setOpen(!open)
            : () => history.push(path)
        }
      >
        {icon}
        {'    '}
        {title}
      </Link>
      <Dropdown className={open ? 'show' : ''}>
        {dropdown !== undefined &&
          dropdown.map(
            item =>
              !item?.hidden && (
                <DropItem onClick={() => history.push(item.path)}>
                  {item.icon}
                  <span>{item.title}</span>
                </DropItem>
              ),
          )}
      </Dropdown>
    </Container>
  );
};

NavLink.defaultProps = defaultProps;

export default NavLink;
