import React, { ReactNode } from 'react';
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';

import { Container, Body, Content } from './styles';

interface AdminProps {
  children: ReactNode;
}

export default function Admin({ children }: AdminProps) {
  return (
    <Container>
      <Sidebar />
      <Body>
        <Topbar />
        <Content>{children}</Content>
      </Body>
    </Container>
  );
}
