import React, { ChangeEvent } from 'react';
import TextArea from '../../../components/TextArea';
import { Text } from '../../../styles/typography';
import { handleChange } from '../../../utils';

// import { Container } from './styles';

interface ObjectiveProps {
  setData: (prevState: any) => void;
  data: any;
}

const Objectives = ({ setData, data }: ObjectiveProps) => {
  return (
    <>
      <Text>O nosso objetivo é fazer acontecer! E o seu?</Text>
      <TextArea
        name="shortTerm"
        placeholder="Quais seus objetivos a curto prazo na Mind? *"
        onChange={e => handleChange(e, setData)}
        defaultValue={data.shortTerm}
      />
      <TextArea
        name="longTerm"
        placeholder="Quais seus objetivos a longo prazo na Mind? *"
        onChange={e => handleChange(e, setData)}
        defaultValue={data.longTerm}
      />
    </>
  );
};

export default Objectives;
