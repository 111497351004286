import React from 'react';
import Admin from '../../layout/Admin';

// import { Container } from './styles';

const Dashboard: React.FC = () => {
  return <Admin>{''}</Admin>;
};

export default Dashboard;
