import React, { useEffect } from 'react';
import { Description, Text } from '../../styles/typography';
import { PSelData } from '../../types';
import { Column, Container, Row } from './styles';

// import { Container } from './styles';

const DetailPanel = ({ data }: any) => {
  return (
    <Container>
      <Row>
        <Column>
          <Text style={{ textAlign: 'left' }}>Descrição</Text>

          <Description>
            {data.course} - {data.university}
            <br />
            Formação prevista para {data.graduation}
            <br />
            Linguagens conhecidas: {data.languages}
            {data?.indicatedBy && (
              <>
                <br />
                Indicado por: {data.indicatedBy}
              </>
            )}
            {data?.discoveredBy && (
              <>
                <br />
                Descoberto através: {data.discoveredBy}
              </>
            )}
          </Description>
        </Column>
        <Column>
          <Text style={{ textAlign: 'left' }}>Metas a Curto Prazo</Text>
          <Description>{data.shortTerm}</Description>
        </Column>
        <Column>
          <Text style={{ textAlign: 'left' }}>Metas a Longo Prazo</Text>
          <Description>{data.longTerm}</Description>
        </Column>
      </Row>
    </Container>
  );
};

export default DetailPanel;
