import React from 'react';

// Icons
import {
  FaTachometerAlt,
  FaUserCog,
  FaSearch,
  FaHome,
  FaUser,
  FaChess,
  FaUserPlus,
} from 'react-icons/fa';

// Pages
import Dashboard from '../pages/Dashboard';
import FormPSel from '../pages/FormPSel';
import Login from '../pages/Login';
import PSel from '../pages/PSel';

const routes = {
  protected: [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: FaTachometerAlt,
      component: Dashboard,
      hidden: false,
      dropdown: undefined,
    },
    {
      title: 'Processo Seletivo',
      path: '/processo-seletivo',
      icon: FaTachometerAlt,
      component: PSel,
      hidden: false,
      dropdown: undefined,
    },
    {
      title: 'Novo Usuário',
      path: '/cadastro-usuario',
      icon: FaUserPlus,
      component: PSel,
      hidden: false,
      dropdown: undefined,
    },
  ],

  public: [
    {
      title: 'Login',
      path: '/login',
      hidden: true,
      component: Login,
    },
    {
      title: 'Processo Seletivo',
      path: '/',
      hidden: true,
      component: FormPSel,
    },
  ],
};
export default routes;
