import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import Global from './styles/global';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <Global />
      <Routes />
      <ToastContainer />
    </>
  );
};

export { App };
