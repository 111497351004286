import React, { ChangeEvent, SetStateAction } from 'react';
import { FaEnvelope, FaIdCard, FaPhone, FaUser } from 'react-icons/fa';
import IconTextInput from '../../../components/IconTextInput';
import MaskedIconTextInput from '../../../components/IconTextInput/masked';
import { Text } from '../../../styles/typography';
import { handleChange } from '../../../utils';

// import { Container } from './styles';
interface PersonalProps {
  setData: (prevState: any) => void;
  data: any;
}

const Personal = ({ setData, data }: PersonalProps) => {
  return (
    <>
      <Text>
        Vamos nos conhecer? <br />
        Insira seus dados!
      </Text>
      <IconTextInput
        name="name"
        placeholder="Nome Completo *"
        icon={FaUser}
        defaultValue={data.name}
        onChange={e => handleChange(e, setData)}
      />
      <MaskedIconTextInput
        name="cpf"
        placeholder="CPF *"
        format="###.###.###-##"
        icon={FaIdCard}
        defaultValue={data.cpf}
        onChange={e => handleChange(e, setData)}
      />
      <IconTextInput
        name="email"
        placeholder="Email *"
        icon={FaEnvelope}
        defaultValue={data.email}
        onChange={e => handleChange(e, setData)}
      />
      <MaskedIconTextInput
        name="phone"
        placeholder="Celular *"
        format="(##) #####-####"
        icon={FaPhone}
        defaultValue={data.phone}
        onChange={e => handleChange(e, setData)}
      />
    </>
  );
};

export default Personal;
