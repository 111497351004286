import React, { ChangeEvent } from 'react';
import { FaQuestionCircle, FaUserFriends } from 'react-icons/fa';
import IconTextInput from '../../../components/IconTextInput';
import { Text } from '../../../styles/typography';
import { handleChange } from '../../../utils';

// import { Container } from './styles';
interface RecomendationProps {
  setData: (prevState: any) => void;
  data: any;
}
const Recomendation = ({ setData, data }: RecomendationProps) => {
  return (
    <>
      <Text>
        Só mais um pouquinho! <br />
        Como você chegou até nós?
      </Text>
      <IconTextInput
        name="indicatedBy"
        placeholder="Foi indicado por algum Minder?"
        icon={FaUserFriends}
        defaultValue={data.indicatedBy}
        onChange={e => handleChange(e, setData)}
      />
      <IconTextInput
        name="discoveredBy"
        placeholder="Como ficou sabendo da Mind?"
        icon={FaQuestionCircle}
        defaultValue={data.discoveredBy}
        onChange={e => handleChange(e, setData)}
      />
    </>
  );
};

export default Recomendation;
