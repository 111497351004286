import React, { Dispatch, SetStateAction } from 'react';

import { Container, NumberHolder, Line } from './styles';

interface StepperProps {
  steps: number;
  actualStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}

const Stepper = ({ steps, actualStep, setStep }: StepperProps) => {
  const changeStep = (clicked: number) => {
    console.log(clicked);
    if (clicked < actualStep) {
      setStep(clicked);
    }
  };

  const handleSteps = () => {
    const stepsArray = [];
    for (let i = 1; i <= steps; i++) {
      stepsArray.push(i);
    }
    return stepsArray.map(value => {
      return value === 1 ? (
        <NumberHolder
          onClick={() => changeStep(value)}
          className={value === actualStep ? 'active' : ''}
        >
          {value}
        </NumberHolder>
      ) : (
        <>
          <Line />
          <NumberHolder
            onClick={() => changeStep(value)}
            className={value === actualStep ? 'active' : ''}
          >
            {value}
          </NumberHolder>
        </>
      );
    });
  };

  return <Container>{handleSteps()}</Container>;
};

export default Stepper;
