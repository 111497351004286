import styled from 'styled-components';

export const Container = styled.div`
  background: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
`;

export const Box = styled.div`
  background: var(--primary-600);
  display: flex;
  flex-direction: column;
  width: min(80%, 600px);
  min-height: 450px;
  border-radius: 5px;
  padding: 30px;

  box-shadow: 2px 2px 30px 10px rgba(40, 40, 40, 0.2);
`;
export const Form = styled.div`
  flex: 1;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
