/* eslint-disable no-constant-condition */
/* eslint-disable react/display-name */
import React, { ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { toast } from 'react-toastify';

// Icons

import { getToken } from '../services/auth';

interface PrivateRouteProps extends RouteProps {
  component?: ComponentType | any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        getToken() ? (
          <Component {...props} />
        ) : (
          <>
            {toast.error(
              'Oops, você precista estar logado para acessar esta página!',
            )}
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          </>
        )
      }
    />
  );
};
