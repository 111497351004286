import { createMuiTheme } from '@material-ui/core';
import { ChangeEvent } from 'react';

export const handleChange = (
  e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  setState: any,
) => {
  const { name, value } = e.target;
  setState((prevState: any) => ({
    ...prevState,
    [name]: value,
  }));
};

export const tableTheme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: 'var(--primary)',
        boxShadow: '0 0 30px 10px rgba(40, 40, 40, 0.4)',
        color: 'var(--white)',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'var(--white)',
        '&:hover': {
          color: 'var(--secondary-100)',
        },
        '&:active': {
          color: '#fff',
        },
      },
    },
    MuiInput: {
      underline: {
        color: 'var(--white)',
        '&:after': {
          borderBottomColor: 'var(--secondary-100)',
        },
      },
    },
  },
});
